const french = {
  navigation: {
    solutions: "Solutions",
    about: "À propos",
    contact: "Contact",
    team: "Équipe",
  },
  footer: {
    tag: "",
    solutions: "Solutions",
    company: "Entreprise",
    about: "À propos",
    contact: "Contact",
    legal: "Mentions légales",
    privacy: "Confidentialité",
    team: "Équipe",
  },
  home: {
    hero: {
      title: {
        one: "Recyclage de nouvelle génération de",
        two: "matériaux critiques",
      },
      subtitle: "L'avenir du recyclage des ressources libéré.",
      cta: "Commencez ici",
    },
    main: {
      title: "Problème",
      subtitle:
        "Le recyclage actuel de la masse noire des batteries lithium-ion ne parvient pas à récupérer une valeur significative car il n'est pas optimisé pour les fractions autres que les métaux de base.",
      text: "Les défis actuels du recyclage et de la chaîne d'approvisionnement incluent des coûts élevés, des dangers environnementaux et une récupération de matériaux insuffisante. Nous avons une opportunité d'optimiser ce processus en améliorant la récupération des matériaux, en réduisant les pertes lors du recyclage des batteries (graphite et électrolyte) et en minimisant les coûts de transport. Adapter les processus pour traiter différents types de batteries, y compris les LFP/LMFP, tout en gérant l'impact environnemental et en utilisant les actifs des fonderies existants, peut augmenter considérablement leur valeur et répondre efficacement à la demande croissante du marché.",
    },
    info: {
      one: {
        title: "Récupération de matériaux",
        subtitle: "Extraction améliorée",
        text: "Nous aidons les fonderies et les recycleurs à optimiser leur récupération.",
      },
      two: {
        title: "Adaptabilité du processus",
        subtitle: "Traitement flexible",
        text: "Adaptation à un paysage matériel diversifié.",
      },
      three: {
        title: "Amélioration environnementale",
        subtitle: "Pratiques écologiques",
        text: "Minimiser l'impact, maximiser l'efficacité",
      },
    },
    solution: {
      title: "Solution",
      subtitle: "Technologie de l'électroextraction et de l'eau",
      text: {
        one: "GreenLIB est un leader dans le surcyclage des matériaux, transformant la récupération de matériaux comme le lithium et le graphite provenant de batteries usagées. Nous nous engageons à maximiser la récupération des ressources et avons introduit une technique révolutionnaire qui régénère les électrolytes et s'adapte à diverses chimies de batteries, y compris NMC, LFP et LMFP. Notre processus assure une utilisation complète des ressources, nous établissant comme un pionnier dans la récupération durable des matériaux.",
        two: "Nous avançons activement la durabilité environnementale comme un élément central de l'innovation. Nous privilégions les opérations écologiques, réduisant les déchets et rationalisant les processus pour améliorer les pratiques vertes. Nos initiatives en cours dans le recyclage des batteries marquent le début de notre effort soutenu pour atteindre un avenir plus efficace et plus propre. Notre dévouement à la durabilité nous place à l'avant-garde du mouvement de l'économie circulaire.",
      },
      action_one: "Recyclez avec nous",
      action_two: "En savoir plus",
    },
    cta: {
      title: "Découvrez notre approche unique et évolutive.",
      action: "En savoir plus",
    },
  },
  solutions: {
    hero: {
      title: "Alimenter l'avenir du surcyclage des matériaux.",
      subtitle:
        "Sur le marché complexe du recyclage des batteries, GreenLIB se distingue en transformant les défis de l'industrie en opportunités de croissance et d'innovation. En abordant des problèmes critiques tels que les marges bénéficiaires, la conformité environnementale et la qualité des matériaux, notre technologie avancée de surcyclage rationalise les processus, réduit les coûts logistiques et fournit des matériaux de haute pureté, établissant de nouvelles normes de durabilité et d'efficacité face à la demande mondiale croissante. Avec GreenLIB, l'avenir du recyclage des batteries est non seulement plus vert et plus propre, mais également économiquement viable et aligné sur les besoins pressants de notre époque.",
      action: "Recyclez avec nous",
    },
    minerals: {
      title:
        "Les métaux et minéraux essentiels que nous obtenons grâce à notre processus.",
      action: "Sélectionnez un minéral pour en savoir plus !",
      atomic: {
        title: "Numéro atomique {{atomicNumber}}",
        lithium: {
          name: "Lithium",
          num: "7",
          text: "Le lithium est le métal le plus léger et le troisième élément le plus léger (après l'hydrogène et l'hélium) du tableau périodique. Il a une faible densité et est très réactif, ce qui le rend utile pour une variété d'applications, y compris dans les batteries, les céramiques et même les médicaments pour certaines conditions de santé.",
        },
        silver: {
          name: "Argent",
          num: "47",
          text: "L'argent est parfois utilisé dans les électrodes des batteries, mais c'est un phénomène relativement rare. Cependant, l'argent est un métal hautement recyclable qui peut être réutilisé dans la production de nouvelles batteries.",
        },
        manganese: {
          name: "Manganèse",
          num: "25",
          text: "Le manganèse est un composant courant des batteries au lithium-ion. Il est souvent utilisé dans la cathode pour améliorer la densité énergétique de la batterie. Le manganèse peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        iron: {
          name: "Fer",
          num: "26",
          text: "Le fer n'est pas typiquement utilisé dans les batteries, mais il est un composant courant de l'acier, utilisé pour fabriquer des boîtiers de batteries et d'autres composants. Le fer recyclé peut être utilisé pour fabriquer du nouvel acier à ces fins.",
        },
        cobalt: {
          name: "Cobalt",
          num: "27",
          text: "Le cobalt est utilisé dans les batteries au lithium-ion pour augmenter leur densité énergétique. Le cobalt est un minéral précieux, mais son extraction peut avoir des impacts environnementaux négatifs. Le recyclage des batteries peut aider à réduire le besoin de nouvelles extractions et à conserver des ressources précieuses.",
        },
        nickel: {
          name: "Nickel",
          num: "28",
          text: "Le nickel est un composant clé de la cathode de nombreux types de batteries, y compris les batteries au nickel-cadmium, nickel-hydrure métallique et lithium-ion. Le nickel peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        copper: {
          name: "Cuivre",
          num: "29",
          text: "Le cuivre est utilisé dans de nombreux composants de batteries, y compris les fils, les connecteurs et les bornes. Le cuivre est également un métal hautement recyclable qui peut être réutilisé dans la production de nouvelles batteries.",
        },
        aluminium: {
          name: "Aluminium",
          num: "13",
          text: "L'aluminium n'est pas directement utilisé dans les batteries, mais il est souvent utilisé pour fabriquer des boîtiers de batteries et d'autres composants. Le recyclage de l'aluminium peut aider à conserver l'énergie et à réduire les émissions de gaz à effet de serre.",
        },
        carbon: {
          name: "Carbone",
          num: "6",
          text: "Le carbone est utilisé dans l'anode de nombreux types de batteries, y compris les batteries au lithium-ion. Le carbone peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        silicon: {
          name: "Silicium",
          num: "14",
          text: "Le silicium est un métalloïde polyvalent abondant dans la croûte terrestre. Avec sa conductivité électrique, sa résistance à la chaleur et son affinité pour l'oxygène, il est essentiel dans l'électronique, les panneaux solaires, la fibre optique, le béton et la production de verre.",
        },
        electrolyte: {
          name: "Électrolyte",
          num: "LiPF6",
          text: "Le hexafluorophosphate de lithium (LiPF6) est un électrolyte essentiel dans les batteries au lithium-ion, facilitant le transfert d'ions entre les électrodes pour permettre le stockage et la libération d'énergie. Sa haute conductivité ionique et sa stabilité en font un ingrédient clé pour des batteries haute performance dans l'électronique et les véhicules électriques.",
        },
      },
    },
    process: {
      title: "Notre processus",
      flow: {
        step: {
          one: "Masse noire",
          three: "Collecte et réception",
          four: "Stockage & préparation",
          six: "Extraction & raffinement",
          seven: "Fonderies & Pyro",
          eight: "Hydro",
        },
      },
    },
    info: {
      one: {
        title: "Maximisation des ressources",
        text: "Maximiser la récupération des métaux, du lithium, du graphite et des électrolytes à partir de la masse noire est essentiel, s'alignant avec l'évolution réglementaire pour améliorer l'efficacité des ressources.",
      },
      two: {
        title: "Polyvalence du processus",
        text: "Adapter les processus de recyclage pour gérer diverses chimies de batteries comme NMC et LFP/LMFP est clé, garantissant des méthodes de fonderie et de pyrolyse rentables et polyvalentes.",
      },
      three: {
        title: "Amélioration de la durabilité",
        text: "Mettre l'accent sur les opérations écologiques grâce à une gestion efficace du fluor et à la réduction des déchets est critique, en minimisant également la dépendance aux permis de déchets dangereux.",
      },
      four: {
        title: "Optimisation de la valeur",
        text: "Optimiser les actifs des fonderies et intégrer des processus hydrométallurgiques durables augmente la valeur des actifs et soutient les objectifs environnementaux.",
      },
    },
    cta: {
      title:
        "Intéressé pour en savoir plus ? Discutons de comment nous pouvons vous aider.",
      action: "Contactez-nous",
    },
  },
  about: {
    hero: {
      title: "Notre parcours vers un futur durable",
      subtitle:
        "Lancé en 2022, nous sommes prêts à transformer le secteur du surcyclage des matériaux. Avec un processus innovant pour récupérer les composants précieux des batteries, nous transformons les défis de l'industrie en solutions durables. Notre technologie non seulement augmente les taux de recyclage au-delà des 5% actuels mais réduit également les émissions de CO2 et les coûts. Conçu pour la décentralisation, nos installations de prétraitement sur site s'intègrent parfaitement dans les chaînes d'approvisionnement existantes, améliorant la durabilité à long terme. Alors que la demande de batteries augmente, nous sommes prêts à mener avec un surcyclage éco-efficace de la masse noire pour un avenir énergétique plus durable.",
    },
    stat: {
      title: "Notre mission",
      subtitle:
        "Nous sommes animés par la mission de transformer l'industrie du recyclage – en mettant l'accent sur le surcyclage de la masse noire. Notre objectif est de fournir une valeur inégalée grâce à un processus aussi écologique que sécurisé et efficace.",
      text: "Notre engagement est d'inspirer chaque membre de notre équipe et nos partenaires à travers notre dévouement inébranlable à la préservation de l'environnement et à la poursuite incessante de l'innovation. Nous promettons non seulement la faisabilité de notre méthodologie, mais aussi l'extraction consciencieuse de matériaux vitaux, tout pour renforcer le passage mondial vers l'énergie propre.",
      one: {
        label:
          "Nous sommes prêts à révolutionner la récupération de matériaux d'ici 2030, visant à traiter 16 500 tonnes métriques de masse noire annuellement provenant de divers secteurs. Nos installations avancées sont spécialisées dans l'extraction de matériaux de haute qualité, y compris du lithium purifié, du graphite et des électrolytes, ainsi que des alliages propres de nickel/cobalt.",
        value: "Visant 16 500 tonnes métriques annuelles d'ici 2030",
      },
      two: {
        label:
          "Notre technologie ne se contente pas de récupérer des matériaux vitaux, mais illustre également notre engagement envers la gestion des déchets et le contrôle de la pollution. Notre méthodologie est centrée sur une gestion rigoureuse du fluor et une réduction des PFAS, ce qui réduit considérablement l'impact environnemental du recyclage des batteries. Nous visons un écosystème où les déchets chimiques et polluants sont minimisés, en accord avec les normes environnementales strictes.",
        value: "Innover pour l'excellence environnementale",
      },
      three: {
        label:
          "Nos processus flexibles gèrent efficacement divers types de masse noire allant du NMC au LFP/LMFP ou mix, reflétant notre engagement envers la durabilité et la qualité face à la diversité des chimies matérielles. Nous facilitons l'intégration de pratiques plus propres et plus efficaces pour les fonderies/pyro et les processus hydrométallurgiques, garantissant que chaque étape que nous prenons est un pas vers un avenir plus vert.",
        value: "Adaptable à diverses chimies de batteries",
      },
    },
    values: {
      title: "Nos valeurs",
      subtitle:
        "Chez GreenLIB, nous sommes engagés à créer un avenir durable pour notre planète en fournissant un moyen sûr et efficace de surcycler la masse noire.",
      innovation: {
        name: "Innovation",
        description:
          "Chez GreenLIB, nous croyons en la puissance de l'innovation pour entraîner le changement. Nous explorons constamment de nouvelles méthodes avancées pour surcycler la masse noire de manière plus écologique et durable. Notre équipe d'experts travaille sans relâche à développer des technologies de pointe qui nous aident à extraire la valeur maximale de ces batteries tout en minimisant les déchets et les impacts environnementaux.",
      },
      integrity: {
        name: "Intégrité",
        description:
          "L'intégrité est au cœur de tout ce que nous faisons. Nous nous tenons aux normes éthiques les plus élevées, et notre engagement envers la transparence et l'honnêteté est inébranlable. Nous nous efforçons de bâtir la confiance et la crédibilité avec nos clients, fournisseurs et partenaires en faisant toujours ce qu'il faut, même lorsque c'est difficile.",
      },
      environmentalResponsibility: {
        name: "Responsabilité environnementale",
        description:
          "Nous comprenons l'urgence des défis environnementaux auxquels notre planète est confrontée aujourd'hui. En tant que citoyen corporatif responsable, nous nous engageons à réduire notre impact environnemental et à promouvoir la durabilité. Notre processus de recyclage est conçu pour minimiser les déchets et les émissions de carbone, conserver les ressources et contribuer à l'économie circulaire.",
      },
      customerFocus: {
        name: "Orientation client",
        description:
          "Nos clients sont au centre de tout ce que nous faisons. Nous nous engageons à leur fournir le plus haut niveau de service, de produits et de solutions qui répondent à leurs besoins et attentes uniques. Nous écoutons leurs commentaires et nous efforçons constamment d'améliorer nos processus et opérations pour mieux les servir.",
      },
      collaboration: {
        name: "Collaboration",
        description:
          "Nous pensons que la collaboration est essentielle pour atteindre nos objectifs. Nous travaillons étroitement avec nos partenaires, fournisseurs et clients pour créer un avenir plus durable pour notre planète. En travaillant ensemble, nous pouvons trouver des solutions innovantes aux défis environnementaux auxquels nous sommes confrontés et avoir un impact positif sur le monde.",
      },
    },
    cta: {
      title: "Découvrez notre approche unique et évolutive.",
      action: "En savoir plus",
    },
  },
  contact: {
    title: "Contact",
    subtitle: "Construisons ensemble l'avenir de l'énergie durable.",
    form: {
      firstName: "Prénom",
      lastName: "Nom",
      company: "Société",
      email: "Email",
      message: "Message",
      errorMessage: "Obligatoire",
      action: "Parlons-en !",
      success:
        "Merci de nous avoir contacté ! Quelqu'un vous contactera bientôt.",
    },
    info: {
      text: "D'ici 2030, nous visons à traiter 16 500 tonnes métriques de masse noire par an, nous établissant comme des leaders dans la récupération durable des matériaux. Notre technologie propriétaire est à la pointe du surcyclage de la masse noire provenant de diverses industries en ressources précieuses comme le carbonate de lithium, le graphite, l'électrolyte et des alliages métalliques de qualité supérieure. Ce processus garantit non seulement des entrées de haute pureté pour la production de batteries mais privilégie également la gestion environnementale en minimisant les déchets et les substances nocives. Nous collaborons directement avec des fonderies et des processeurs hydro pour affiner et revendre nos solutions avancées sur site. Cette approche réduit les coûts logistiques et matériels, surmontant les barrières traditionnelles au recyclage. Alors que la demande pour un recyclage de batteries efficace et écologique s'intensifie, nous sommes prêts à répondre à ce besoin avec nos technologies à faibles émissions de CO2 et rentables. Contactez-nous pour en savoir plus sur la façon dont nous alimentons la transition énergétique propre.",
    },
  },
  team: {
    title: "Rencontrez notre direction",
    subtitle:
      "Unis par notre dévouement à l'énergie durable et à la protection de l'environnement, notre équipe comprend des innovateurs, des visionnaires et des spécialistes de haut niveau dans le domaine.",
    advisory: "Conseil consultatif",
    titles: {
      advisor: "Conseiller",
      ceo: "PDG",
      coo: "COO",
      cto: "CTO",
      headRD: "Chef de la R&D",
    },
  },
  relationships: {
    title: "Nos relations",
  },
  batteryChart: {
    xLabel: "Composants du coût de la batterie au lithium",
    yLabel: "$ / kWh",
    legend: {
      cathode: "Cathode",
      anode: "Anode",
      electrolyte: "Électrolyte",
      separator: "Séparateur",
      otherMaterials: "Autres matériaux",
      labor: "Main-d'œuvre",
      overhead: "Frais généraux",
      profitMargin: "Marge bénéficiaire",
    },
  },
};

export default french;
